<template>
  <a-drawer
    v-model:visible="visible"
    :maskClosable="false"
    width="1102px"
    :bodyStyle="{ padding: 0 }"
    :title="$t('idp.add_training_program')"
    @close="back"
  >
    <div class="select-content">
      <div class="search">
        <div class="item">
          <a-input
            allowClear
            :placeholder="`${$t('PleaseEnter')}${$t('CM_LB_Name')}`"
            v-model:value="params.name"
            @change="searchChange"
          />
        </div>
      </div>
      <div class="check" v-if="listData.length">
        <div class="check-all">
          <a-checkbox
            v-model:checked="isCheckAll"
            :indeterminate="indeterminate"
            @change="checkAll"
          >
            {{ $t("idp.select_all_this_page") }}
          </a-checkbox>
        </div>
        <div class="checked">
          {{ $t("idp.selected") }}：<span>{{ checkList.length }}</span>
        </div>
      </div>
      <a-spin :spinning="loading">
        <template v-if="listData.length">
          <div class="list">
            <a-checkbox
              class="item"
              v-for="item in listData"
              :key="item.taskId"
              v-model:checked="item.isCheck"
              @change="checkItem($event, item)"
            >
              <div class="cover">
                <img :src="item.coverImg" alt="cover" />
              </div>
              <div class="text">
                <p class="h5">{{ item.taskName }}</p>
                <p class="p">
                  {{ dateFormat(item.startTime) }} -
                  {{ dateFormat(item.endTime) }}
                </p>
              </div>
            </a-checkbox>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :defaultPageSize="12"
              v-model:current="params.page"
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-else
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        />
      </a-spin>
      <div class="btns">
        <a-button type="primary" @click="save">
          {{ $t("idp.save") }}
        </a-button>
        <a-button @click="back">
          {{ $t("idp.return") }}
        </a-button>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";

import { signupList } from "@/api/project";

import { debounce, cloneDeep } from "lodash";
import { dateFormat } from "@/utils/tools";
const renderVNode = (_, { attrs: { vnode } }) => vnode;

export default defineComponent({
  components: {
    renderVNode,
  },
  setup(_, { emit }) {
    const state = reactive({
      visible: false,
      checkList: [],
      isCheckAll: false,
      indeterminate: false,
      params: {
        page: 1,
        pageSize: 12,
        signupIsview: 1,
        name: "",
        folderId: null,
      },
      loading: false,
      listData: [],
      pageTotal: 0,
    });

    const searchChange = debounce(() => {
      state.params.page = 1;
      getList();
    }, 500);

    const getList = () => {
      state.loading = true;
      signupList(state.params).then((res) => {
        state.loading = false;
        if (res.ret === 0) {
          state.listData = res.data.list || [];
          state.pageTotal = res.data.totals;
          let checkNum = 0;
          state.listData.forEach((item) => {
            state.checkList.forEach((item2) => {
              if (item.taskId == item2.taskId) {
                if (item2.resourceId) item.resourceId = item2.resourceId;
                item.isCheck = true;
                checkNum++;
              }
            });
          });
          if (checkNum == state.listData.length) {
            state.isCheckAll = true;
            state.indeterminate = false;
          } else if (checkNum == 0) {
            state.isCheckAll = false;
            state.indeterminate = false;
          } else {
            state.isCheckAll = false;
            state.indeterminate = true;
          }
        }
      });
    };

    const pageChange = (n) => {
      state.params.page = n;
      getList();
    };

    const checkAll = (e) => {
      let val = e.target.checked;
      if (val) state.indeterminate = false;
      state.listData.forEach((item) => {
        if (val) {
          if (!item.isCheck) {
            state.checkList.push(item);
          }
        } else {
          let index = state.checkList.map((o) => o.taskId).indexOf(item.taskId);
          state.checkList.splice(index, 1);
        }
        item.isCheck = val;
      });
    };

    const checkItem = (e, item) => {
      let val = e.target.checked;
      if (val) {
        state.checkList.push(item);
      } else {
        let index = state.checkList.map((o) => o.taskId).indexOf(item.taskId);
        state.checkList.splice(index, 1);
      }
      let checkNum = 0;
      state.listData.forEach((item) => {
        if (item.isCheck) checkNum++;
      });
      if (checkNum == state.listData.length) {
        state.isCheckAll = true;
        state.indeterminate = false;
      } else if (checkNum == 0) {
        state.isCheckAll = false;
        state.indeterminate = false;
      } else {
        state.isCheckAll = false;
        state.indeterminate = true;
      }
    };

    const open = (data) => {
      state.listData = [];
      if (data) {
        state.checkList = cloneDeep(data);
      }
      state.params = {
        page: 1,
        pageSize: 12,
        signupIsview: 1,
        name: "",
        folderId: null,
      };
      getList();
      state.visible = true;
    };

    const back = () => {
      state.visible = false;
    };

    const save = () => {
      emit("save", cloneDeep(state.checkList));
      back();
    };

    return {
      dateFormat,
      ...toRefs(state),
      searchChange,
      pageChange,
      checkAll,
      checkItem,
      open,
      back,
      save,
    };
  },
});
</script>

<style lang="less" scoped>
.select-content {
  padding: 24px 16px 24px 24px;
  .search {
    .mixinFlex();
    flex-wrap: wrap;
    margin-bottom: 12px;
    .item {
      width: 300px;
      margin-right: 24px;
      margin-bottom: 12px;
      & > * {
        width: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .check {
    .mixinFlex(space-between; center);
    margin-bottom: 24px;
    span {
      color: @color-theme;
    }
  }
  .list {
    .mixinFlex();
    flex-wrap: wrap;
    .item {
      width: 252px;
      height: 224px;
      margin: 0 16px 16px 0 !important;
      border-radius: 6px;
      background-color: #fff;
      position: relative;
      box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.15);
      &:nth-child(4n) {
        margin-right: 0 !important;
      }
      ::v-deep(.ant-checkbox) {
        z-index: 1;
        position: absolute;
        top: 8px;
        left: 8px;
        & + span {
          padding: 0;
        }
      }
      .cover {
        .mixinImgWrap(252px; 142px);
        background-color: #fafafa;
        border-radius: 6px 6px 0 0;
      }
      .text {
        padding: 6px 8px;
        p {
          margin: 0;
          &.h5 {
            font-size: 14px;
            color: #333;
            .mixinEllipsis(44px, 2);
            margin-bottom: 6px;
          }
          &.p {
            font-size: 12px;
            color: #666;
            .mixinEllipsis(20px);
          }
        }
      }
    }
  }
  .btns {
    text-align: center;
    margin-top: 16px;
    padding-top: 24px;
    border-top: 1px solid #f4f4f4;
    .ant-btn {
      &:last-child {
        margin-left: 24px;
      }
    }
  }
}
</style>
