<template>
  <a-drawer
    v-model:visible="visible"
    :maskClosable="false"
    width="608px"
    :bodyStyle="{ padding: 0 }"
    :title="$t('idp.add_exam')"
    @close="back"
  >
    <div class="select-content">
      <a-form :model="formData" layout="vertical" ref="formRef">
        <!-- 试卷名称 -->
        <a-form-item
          :label="$t('idp.test_paper_name')"
          name="detailName"
          :rules="{
            required: true,
            message: $t('PleaseSelect') + $t('idp.test_paper_name'),
            trigger: 'change',
          }"
        >
          <a-input
            style="width: 390px"
            disabled
            v-model:value="formData.detailName"
            :placeholder="$t('PleaseSelect') + $t('idp.test_paper_name')"
          />
          <span
            class="input-after btn"
            @click="$refs.paperRef.open(paperDisabledIds)"
          >
            {{ $t("idp.select_test_paper") }}
          </span>
        </a-form-item>
        <!-- 及格线 -->
        <a-form-item
          name="passLine"
          :rules="{
            required: true,
            type: 'number',
            message: $t('PleaseSelect') + $t('idp.test_paper_name'),
            trigger: 'change',
          }"
        >
          <template #label>
            {{ $t("idp.pass_line") }}
            <a-tooltip :title="$t('idp.pass_line_tips')">
              <ExclamationCircleOutlined class="tips-icon" />
            </a-tooltip>
          </template>
          <a-input-number
            style="width: 390px"
            disabled
            v-model:value="formData.passLine"
            :placeholder="$t('PleaseSelect') + $t('idp.test_paper_name')"
          />
          <span class="input-after">%</span>
        </a-form-item>
        <!-- 考试时长 -->
        <a-form-item
          name="examDuration"
          :rules="{
            required: true,
            type: 'number',
            message: $t('PleaseEnter') + $t('idp.exam_duration'),
            trigger: 'change',
          }"
        >
          <template #label>
            {{ $t("idp.exam_duration") }}
            <a-tooltip :title="$t('idp.exam_duration_tips')">
              <ExclamationCircleOutlined class="tips-icon" />
            </a-tooltip>
          </template>
          <a-input-number
            style="width: 390px"
            :min="0"
            :precision="0"
            v-model:value="formData.examDuration"
            :placeholder="$t('PleaseEnter') + $t('idp.exam_duration')"
          />
          <span class="input-after">{{ $t("idp.minute") }}</span>
        </a-form-item>
      </a-form>
      <div class="explain">
        <div class="divider">
          <a-divider>{{ $t("idp.exam_instructions") }}</a-divider>
        </div>
        <p>{{ $t("idp.exam_instructions_1") }}</p>
        <p>{{ $t("idp.exam_instructions_2") }}</p>
        <p>{{ $t("idp.exam_instructions_3") }}</p>
        <p>{{ $t("idp.exam_instructions_4") }}</p>
        <p>{{ $t("idp.exam_instructions_5") }}</p>
        <p>{{ $t("idp.exam_instructions_6") }}</p>
      </div>
      <div class="btns">
        <a-button type="primary" @click="save">
          {{ $t("idp.save") }}
        </a-button>
        <a-button @click="back">
          {{ $t("idp.return") }}
        </a-button>
      </div>
    </div>
  </a-drawer>
  <paperDrawer ref="paperRef" @save="addPaper" />
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
} from "vue";

import { cloneDeep } from "lodash";

import paperDrawer from "./paper.vue";

export default defineComponent({
  components: {
    paperDrawer,
  },
  setup(_, { emit }) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      paperDisabledIds: [],
      formData: {
        detailId: 0,
        resourceId: 0,
        detailName: "",
        passLine: 0,
        examDuration: 30,
        exam: {},
      },
    });
    const formRef = ref(null);

    const addPaper = (data) => {
      state.formData.detailName = data.paperName;
      state.formData.passLine = Number.parseInt(
        (data.passScore / data.totalScore) * 100
      );
      state.formData.exam = {
        paperId: data.paperId,
        paperName: data.paperName,
        passScore: data.passScore,
        totalScore: data.totalScore,
        totalNum: data.totalNum,
        taskName: data.taskName,
      };
    };

    const open = (data) => {
      if (data) {
        state.paperDisabledIds = data.map((item) => item.paperId);
      }
      state.formData = {
        detailId: 0,
        resourceId: 0,
        detailName: "",
        passLine: 0,
        examDuration: 30,
        exam: {},
      };
      state.visible = true;
      formRef.value && formRef.value.resetFields();
    };

    const back = () => {
      state.visible = false;
    };

    const save = () => {
      formRef.value.validate().then(() => {
        if (state.paperDisabledIds.includes(state.formData.exam.paperId)) {
          proxy.$message.warn(
            proxy.$t("idp.current_study_plan_already_has_exam_for_this_paper")
          );
          return false;
        }
        state.formData.exam.limitTime = state.formData.examDuration * 60;
        state.formData.exam.passLine = state.formData.passLine;
        emit("save", cloneDeep(state.formData));
        back();
      });
    };

    return {
      ...toRefs(state),
      formRef,
      addPaper,
      open,
      back,
      save,
    };
  },
});
</script>

<style lang="less" scoped>
.select-content {
  padding: 24px 16px 24px 24px;
  .tips-icon {
    color: #999;
    padding-left: 6px;
  }
  .input-after {
    margin-left: 10px;
    color: #666;
    &.btn {
      color: @color-theme;
      cursor: pointer;
    }
  }
  .explain {
    .divider {
      padding: 24px 0 12px;
    }
    p {
      font-size: 14px;
      color: #999;
      line-height: 22px;
      margin-bottom: 8px;
    }
  }
  .btns {
    text-align: center;
    margin-top: 24px;
    padding-top: 24px;
    .ant-btn {
      &:last-child {
        margin-left: 24px;
      }
    }
  }
}
</style>
