<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("idp.add_idp_learning_plan") }}
      <!-- 添加 编辑IDP学习计划 -->
    </div>
    <a-form :model="formData" layout="vertical" ref="formRef">
      <div class="part-title">{{ $t("idp.basic_information") }}</div>
      <div class="part-main">
        <secret
          :value="formData.secretData"
          @secret:change="(e) => (formData.secretData = e)"
        />
        <a-row :gutter="24">
          <a-col :span="12">
            <!-- 计划年度 -->
            <a-form-item
              :label="$t('idp.plan_year')"
              name="planYear"
              :rules="{
                required: true,
                type: 'number',
                message: $t('PleaseSelect') + $t('idp.plan_year'),
                trigger: 'change',
              }"
            >
              <a-select
                allowClear
                show-search
                v-model:value="formData.planYear"
                :placeholder="$t('PleaseSelect') + $t('idp.plan_year')"
                @change="planYearChange"
              >
                <a-select-option
                  v-for="year in yearOpts"
                  :key="year"
                  :value="year"
                  >{{ year }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 计划名称 -->
            <a-form-item
              name="planName"
              :rules="{
                required: true,
                message: $t('PleaseSelect') + $t('idp.plan_year'),
                trigger: 'change',
              }"
            >
              <template #label>
                {{ $t("idp.plan_name") }}
                <a-tooltip :title="$t('idp.plan_name_tips')">
                  <ExclamationCircleOutlined class="tips-icon" />
                </a-tooltip>
              </template>
              <a-input
                v-model:value="formData.planName"
                disabled
                :placeholder="$t('PleaseSelect') + $t('idp.plan_year')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 监督人 -->
            <a-form-item
              name="collaboratorName"
              :rules="{
                required: false,
                message: $t('PleaseSelect') + $t('idp.supervisor'),
                trigger: 'change',
              }"
            >
              <template #label>
                {{ $t("idp.supervisor") }}
                <a-tooltip :title="$t('idp.supervisor_tips')">
                  <ExclamationCircleOutlined class="tips-icon" />
                </a-tooltip>
              </template>
              <span
                class="input-label-right"
                @click="$refs.memberRef.open(collaboratorData)"
                >{{ $t("idp.choice") }}</span
              >
              <CloseCircleFilled
                class="input-close"
                v-if="formData.collaboratorName"
                @click="delCollaborator"
              />
              <a-input
                v-model:value="formData.collaboratorName"
                disabled
                :placeholder="$t('PleaseSelect') + $t('idp.supervisor')"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="part-title">{{ $t("idp.learning_content") }}</div>
      <div class="part-main">
        <div class="add-btns" v-if="companyInfo.menu">
          <a-button
            v-if="companyInfo.menu.includes('course')"
            @click="$refs.courseRef.open(courseList)"
          >
            <PlusOutlined /> {{ $t("idp.online_courses") }}
          </a-button>
          <a-button
            v-if="companyInfo.menu.includes('exam')"
            @click="$refs.examRef.open(examList)"
          >
            <PlusOutlined /> {{ $t("idp.online_exams") }}
          </a-button>
          <a-button
            v-if="companyInfo.menu.includes('project')"
            @click="$refs.projectRef.open(projectList)"
          >
            <PlusOutlined /> {{ $t("idp.training_program") }}
          </a-button>
          <a-button @click="$refs.homeworkRef.open()">
            <PlusOutlined /> {{ $t("idp.homework") }}
          </a-button>
          <a-button
            v-if="companyInfo.menu.includes('questionnaire')"
            @click="$refs.questionnaireRef.open(questionnaireList)"
          >
            <PlusOutlined /> {{ $t("idp.questionnaire") }}
          </a-button>
        </div>
        <div class="add-list">
          <div class="add-list-content">
            <vue-draggable
              v-model="formData.details"
              force-fallback="true"
              animation="1000"
              handle=".drag-icon"
            >
              <div
                class="item"
                v-for="(item, index) in formData.details"
                :key="index"
              >
                <div class="drag-icon">
                  <img src="@/assets/image/drag.png" alt="drag" />
                </div>
                <div class="index">
                  {{ formData.details.length - index }}
                </div>
                <div class="type">{{ getDetailType(item.resourceType) }}</div>
                <div class="name" v-if="item.resourceType == 5">
                  {{ item.homework.homeworkName }}
                </div>
                <div class="name" v-else>{{ item.detailName }}</div>
                <div class="ctrl">
                  <div
                    class="edit"
                    v-if="item.resourceType == 5"
                    @click="$refs.homeworkRef.open(item.homework, index)"
                  >
                    <FormOutlined />
                  </div>
                  <a-popconfirm
                    :title="$t('LB_Doc_Confirm_Delete')"
                    @confirm="toDelete(item, index)"
                  >
                    <div class="delete">
                      <DeleteOutlined />
                    </div>
                  </a-popconfirm>
                </div>
              </div>
            </vue-draggable>
            <div class="empty" v-if="!formData.details.length">
              <a-empty
                :image="require('@/assets/image/no_data_3.png')"
                :image-style="{ height: '106px' }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <a-button type="primary" :loading="loading" @click="save">
          {{ $t("idp.save") }}
        </a-button>
        <a-button @click="back">
          {{ $t("idp.return") }}
        </a-button>
      </div>
    </a-form>
    <memberDrawer ref="memberRef" @save="addCollaborator" />
    <courseDrawer ref="courseRef" @save="addCourse" />
    <examDrawer ref="examRef" @save="addExam" />
    <projectDrawer ref="projectRef" @save="addProject" />
    <homeworkDrawer ref="homeworkRef" @save="addHomework" />
    <questionnaireDrawer ref="questionnaireRef" @save="addQuestionnaire" />
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { idpDetail, idpSubmit } from "@/api/idp";

import { cloneDeep } from "lodash";
import { getDetailType } from "@/utils/business";

import { VueDraggable } from "vue-draggable-plus";
import secret from "@/components/secret.vue";
import memberDrawer from "./drawer/member.vue";
import courseDrawer from "./drawer/course.vue";
import examDrawer from "./drawer/exam.vue";
import projectDrawer from "./drawer/project.vue";
import homeworkDrawer from "./drawer/homework.vue";
import questionnaireDrawer from "./drawer/questionnaire.vue";

export default {
  components: {
    VueDraggable,
    secret,
    memberDrawer,
    courseDrawer,
    examDrawer,
    projectDrawer,
    homeworkDrawer,
    questionnaireDrawer,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const companyInfo = computed(() => store.getters.companyInfo);
    const state = reactive({
      loading: false,
      formData: {
        planId: Number(route.query.planId || 0),
        planYear: null,
        planName: "",
        collaborator: 0,
        collaboratorName: "",
        details: [],
      },
      collaboratorData: {},
      courseList: [],
      projectList: [],
      examList: [],
      questionnaireList: [],
      yearOpts: [],
    });

    for (let i = 0; i < 16; i++) {
      state.yearOpts.push(2024 - 5 + i);
    }

    if (state.formData.planId) {
      idpDetail(state.formData.planId).then((res) => {
        if (res.ret == 0) {
          let d = res.data;
          let details = d.details || [];
          details = details.reverse();
          state.formData = {
            planId: d.planId,
            planYear: d.planYear,
            planName: d.planName,
            collaborator: d.collaborator,
            collaboratorName: d.collaboratorName,
            details: details,
          };
          state.collaboratorData = {
            id: d.collaborator,
            name: d.collaboratorName,
          };
          state.courseList = [];
          state.projectList = [];
          state.questionnaireList = [];
          details.forEach((item) => {
            switch (item.resourceType) {
              case 2:
                state.courseList.push({
                  resourceType: 2,
                  resourceId: item.detailId,
                  courseName: item.detailName,
                  courseId: item.resourceId,
                });
                break;
              case 3:
                state.examList.push({
                  resourceType: 3,
                  paperId: item.paperId,
                });
                break;
              case 34:
                state.projectList.push({
                  resourceType: 34,
                  resourceId: item.detailId,
                  taskName: item.detailName,
                  taskId: item.resourceId,
                });
                break;
              case 33:
                state.questionnaireList.push({
                  resourceType: 33,
                  resourceId: item.detailId,
                  questionnaireName: item.detailName,
                  questionnaireId: item.resourceId,
                });
                break;
            }
          });
        }
      });
    }

    const planYearChange = () => {
      if (state.formData.planYear) {
        state.formData.planName =
          state.formData.planYear + 'IDP';
      } else {
        state.formData.planName = "";
      }
    };

    const addCollaborator = (data) => {
      state.collaboratorData = data;
      state.formData.collaborator = data.id;
      state.formData.collaboratorName = data.name;
    };

    const delCollaborator = () => {
      state.collaboratorData = {};
      state.formData.collaborator = 0;
      state.formData.collaboratorName = "";
    };

    const addCourse = (data) => {
      state.courseList = data;
      state.courseList.forEach((item) => {
        let index = state.formData.details
          .map((o) => o.resourceId)
          .indexOf(item.courseId);
        if (index == -1) {
          let obj = {
            resourceType: 2,
            resourceId: item.courseId,
            detailName: item.courseName,
          };
          if (item.resourceId) obj.resourceId = item.resourceId;
          state.formData.details.unshift(obj);
        }
      });
      let tempDetails = cloneDeep(state.formData.details);
      state.formData.details.forEach((item, index) => {
        if (item.resourceType == 2) {
          let index2 = state.courseList
            .map((o) => o.courseId)
            .indexOf(item.resourceId);
          if (index2 == -1) {
            tempDetails[index].del = true;
          }
        }
      });
      state.formData.details = tempDetails.filter((o) => !o.del);
    };

    const addExam = (data) => {
      data.resourceType = 3;
      state.formData.details.unshift(data);
      state.examList.push({
        resourceType: 3,
        paperId: data.exam.paperId,
      });
    };

    const addProject = (data) => {
      state.projectList = data;
      state.projectList.forEach((item) => {
        let index = state.formData.details
          .map((o) => o.resourceId)
          .indexOf(item.taskId);
        if (index == -1) {
          let obj = {
            resourceType: 34,
            resourceId: item.taskId,
            detailName: item.taskName,
          };
          if (item.resourceId) obj.resourceId = item.resourceId;
          state.formData.details.unshift(obj);
        }
      });
      let tempDetails = cloneDeep(state.formData.details);
      state.formData.details.forEach((item, index) => {
        if (item.resourceType == 34) {
          let index2 = state.projectList
            .map((o) => o.taskId)
            .indexOf(item.resourceId);
          if (index2 == -1) {
            tempDetails[index].del = true;
          }
        }
      });
      state.formData.details = tempDetails.filter((o) => !o.del);
    };

    const addHomework = ({ data, index }) => {
      if (index != -1) {
        state.formData.details[index].homework = data;
      } else {
        state.formData.details.unshift({
          resourceType: 5,
          homework: data,
        });
      }
    };

    const addQuestionnaire = (data) => {
      state.questionnaireList = data;
      state.questionnaireList.forEach((item) => {
        let index = state.formData.details
          .map((o) => o.resourceId)
          .indexOf(item.questionnaireId);
        if (index == -1) {
          let obj = {
            resourceType: 33,
            resourceId: item.questionnaireId,
            detailName: item.questionnaireName,
          };
          if (item.resourceId) obj.resourceId = item.resourceId;
          state.formData.details.unshift(obj);
        }
      });
      let tempDetails = cloneDeep(state.formData.details);
      state.formData.details.forEach((item, index) => {
        if (item.resourceType == 33) {
          let index2 = state.questionnaireList
            .map((o) => o.questionnaireId)
            .indexOf(item.resourceId);
          if (index2 == -1) {
            tempDetails[index].del = true;
          }
        }
      });
      state.formData.details = tempDetails.filter((o) => !o.del);
    };

    const toDelete = (item, index) => {
      state.formData.details.splice(index, 1);
      let index2 = -1;
      switch (item.resourceType) {
        case 2:
          index2 = state.courseList
            .map((o) => o.courseId)
            .indexOf(item.resourceId);
          if (index2 != -1) state.courseList.splice(index2, 1);
          break;
        case 33:
          index2 = state.questionnaireList
            .map((o) => o.questionnaireId)
            .indexOf(item.resourceId);
          if (index2 != -1) state.questionnaireList.splice(index2, 1);
          break;
        case 34:
          index2 = state.projectList
            .map((o) => o.taskId)
            .indexOf(item.resourceId);
          if (index2 != -1) state.projectList.splice(index2, 1);
          break;
      }
    };

    const formRef = ref(null);
    const save = () => {
      formRef.value.validate().then(() => {
        let params = JSON.parse(JSON.stringify(state.formData));
        if (params.details.length <= 0) {
          proxy.$message.warn(proxy.$t("idp.learning_content_tips"));
          return false;
        }
        params.details = params.details.reverse();
        idpSubmit(params).then((res) => {
          if (res.ret == 0) {
            proxy.$message.success(proxy.$t("CM_Success"));
            back();
          }
        });
      });
    };

    const back = () => {
      router.go(-1);
    };

    return {
      getDetailType,
      companyInfo,
      ...toRefs(state),
      planYearChange,
      addCollaborator,
      delCollaborator,
      addCourse,
      addExam,
      addProject,
      addHomework,
      addQuestionnaire,
      toDelete,
      formRef,
      save,
      back,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
.part-title {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  margin: 12px 0 24px;
  padding-left: 16px;
  &::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 4px;
    height: 24px;
    background-color: @color-theme;
    border-radius: 10px;
  }
}
.part-main {
  padding: 0 16px;
  .tips-icon {
    color: #999;
    padding-left: 6px;
  }
  .input-label-right {
    position: absolute;
    right: 2px;
    top: -30px;
    color: @color-theme;
    cursor: pointer;
  }
  .add-btns {
    .ant-btn {
      margin-right: 12px;
    }
  }
  .add-list {
    padding: 12px 0;
    background-color: #fafafa;
    margin-top: 16px;
    &-content {
      padding: 0 12px;
      max-height: 600px;
      overflow-y: auto;
    }
    .item {
      background-color: #fff;
      border: 1px solid #d9d9d9;
      height: 36px;
      padding: 8px;
      margin-bottom: 12px;
      .mixinFlex(space-between; center);
      line-height: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .drag-icon {
        width: 20px;
        height: 20px;
        cursor: move;
        user-select: none;
        img {
          height: 100%;
        }
      }
      .index {
        width: 30px;
        text-align: center;
      }
      .type {
        width: 100px;
        .mixinEllipsis(20px);
      }
      .name {
        width: calc(100% - 220px);
        .mixinEllipsis(20px);
      }
      .ctrl {
        width: 50px;
        .mixinFlex(flex-end; center);
        & > div {
          width: 25px;
          text-align: center;
          cursor: pointer;
          &.edit {
            color: #377dff;
          }
          &.delete {
            color: #e8673e;
          }
        }
      }
    }
    .empty {
      padding-top: 16px;
    }
  }
}
.input-close {
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 10px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
}
.btns {
  text-align: center;
  margin-top: 24px;
  .ant-btn {
    &:last-child {
      margin-left: 12px;
    }
  }
}
</style>
