<template>
  <a-drawer
    v-model:visible="visible"
    :maskClosable="false"
    width="768px"
    :bodyStyle="{ padding: 0 }"
    :title="$t('idp.add_homework')"
    @close="back"
  >
    <div class="select-content">
      <a-form :model="formData" layout="vertical" ref="formRef">
        <a-form-item
          :label="$t('idp.assignment_name')"
          name="homeworkName"
          :rules="{
            required: true,
            message: $t('PleaseEnter') + $t('idp.assignment_name'),
            trigger: 'change',
          }"
        >
          <a-input
            v-model:value="formData.homeworkName"
            :placeholder="$t('PleaseEnter') + $t('idp.assignment_name')"
            :disabled="formData.status == 1"
          />
        </a-form-item>
        <a-form-item
          :label="$t('idp.homework_time')"
          name="homeworkTime"
          :rules="{
            required: true,
            type: 'array',
            message: $t('PleaseSelect') + $t('idp.homework_time'),
            trigger: 'change',
          }"
        >
          <a-range-picker
            allowClear
            v-model:value="formData.homeworkTime"
            format="YYYY-MM-DD HH:mm"
            valueFormat="YYYY-MM-DD HH:mm"
            :show-time="{
              format: 'HH:mm',
              defaultValue: [
                moment('00:00', 'HH:mm'),
                moment('23:59', 'HH:mm'),
              ],
            }"
          >
            <template #suffixIcon>
              <CalendarOutlined />
            </template>
          </a-range-picker>
        </a-form-item>
        <a-form-item
          name="steps"
          :rules="{
            required: true,
            type: 'array',
            message: $t('PleaseEnter') + $t('idp.assignment_content'),
            trigger: 'change',
          }"
        >
          <template #label>
            {{ $t("idp.assignment_content") }}
            <a-tooltip :title="$t('idp.assignment_content_tips')">
              <ExclamationCircleOutlined class="tips-icon" />
            </a-tooltip>
          </template>
          <div class="steps" v-if="formData.status == 1">
            <div
              class="steps-item"
              v-for="(item, index) in formData.steps"
              :key="index"
            >
              <div class="steps-item-head">
                <h6>{{ $t("homework.step", [index + 1]) }}</h6>
                <!-- 第 步 -->
              </div>
              <div class="steps-item-content" v-if="item.content">
                {{ item.content }}
              </div>
              <div class="attachments" v-if="item.attachments">
                <div
                  class="attachments-item"
                  v-for="(file, fileIndex) in item.attachments || []"
                  :key="fileIndex"
                >
                  <p>
                    {{
                      file.fileName +
                      file.filePath.substr(file.filePath.lastIndexOf("."))
                    }}
                  </p>
                  <DownloadOutlined
                    class="down"
                    @click="fileDownload(file.sourceFilePath, file.fileName)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="steps" v-else>
            <div
              class="steps-item"
              v-for="(item, index) in formData.steps"
              :key="index"
            >
              <div class="steps-item-head">
                <h6>{{ $t("homework.step", [index + 1]) }}</h6>
                <!-- 第 步 -->
                <div class="ctrl">
                  <div class="ctrl-left">
                    <template v-if="!item.editing">
                      <span class="span">{{
                        $t("homework.attachment", [item.files.length])
                      }}</span>
                      <!-- 个附件 -->
                      <span class="span"
                        >{{ $t("Pub_Tab_Time") }}：{{
                          dateFormat(item.lastTime)
                        }}</span
                      >
                      <!-- 时间： -->
                      <a-tooltip>
                        <template #title>{{ $t("BN_Comm_Edit") }}</template>
                        <!-- 编辑 -->
                        <EditOutlined
                          class="ctrl-icon edit"
                          @click="stepEdit(index)"
                        />
                      </a-tooltip>
                    </template>
                    <a-tooltip v-else>
                      <template #title>{{ $t("CM_Save") }}</template>
                      <!-- 保存 -->
                      <SaveOutlined
                        class="ctrl-icon edit"
                        @click="stepSave(index)"
                      />
                    </a-tooltip>
                  </div>
                  <!-- 确定要删除该步骤吗？ -->
                  <a-popconfirm
                    placement="left"
                    :title="$t('homework.sure_delete_step')"
                    @confirm="stepDel(index)"
                  >
                    <a-tooltip v-model:visible="item.delTips">
                      <template #title>{{ $t("CM_Delete") }}</template>
                      <!-- 删除 -->
                      <CloseOutlined
                        class="ctrl-icon del"
                        @click="item.delTips = false"
                      />
                    </a-tooltip>
                  </a-popconfirm>
                </div>
              </div>
              <div class="steps-item-content">
                <a-textarea
                  v-if="item.editing"
                  style="margin: 8px 0 10px"
                  :rows="4"
                  v-model:value="item.content"
                  :placeholder="$t('homework.optional')"
                />
                <!-- 选填）请填写作业步骤内容，提交长内容时，建议先使用客户端软件编辑好，再粘贴到这里。 -->
                <div class="text" v-else-if="item.content">
                  {{ item.content }}
                </div>
              </div>
              <div class="steps-item-files" v-if="item.editing">
                <div class="up-ctrl">
                  <a-button @click="handleUpload(index)">
                    <UploadOutlined />
                    {{ $t("LB_UploadingAttachments") }}
                  </a-button>
                  <!-- 上传附件 -->
                  <p class="explain">
                    {{ $t("upload.accept", [5, "200M"]) }}
                  </p>
                  <!-- 支持图片、文档、音频、视频，最多可上传5个附件，单个文件不超过200M -->
                </div>
                <ul class="upload-list" v-if="item.files.length">
                  <li
                    class="file-item"
                    v-for="(file, iFile) in item.files"
                    :key="iFile"
                  >
                    <PaperClipOutlined />
                    <span class="file-item-name">
                      <template v-if="file.secretData">
                        {{ htsySecret[file.secretData] }}
                      </template>
                      {{ file.name }}
                    </span>
                    <DeleteOutlined
                      class="del"
                      @click="removeFile(index, iFile)"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="steps-add">
              <a-button type="dashed" block @click="stepAdd">
                <PlusOutlined /> {{ $t("homework.add_steps") }}
                <!-- 添加步骤 -->
              </a-button>
            </div>
          </div>
        </a-form-item>
        <a-form-item
          :label="$t('idp.complete_standard')"
          name="completeCondition"
        >
          <a-radio-group
            v-model:value="formData.completeCondition"
            :disabled="formData.status == 1"
          >
            <a-radio :value="2">{{ $t("idp.reviewed_and_approved") }}</a-radio>
            <a-radio :value="3">{{
              $t("idp.evaluation_form_evaluation")
            }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          v-if="formData.completeCondition == 3"
          :label="$t('idp.evaluation_form')"
          name="evaluationName"
          :rules="{
            required: true,
            message: $t('PleaseSelect') + $t('idp.evaluation_form'),
            trigger: 'change',
          }"
        >
          <a-input
            style="width: 460px"
            disabled
            v-model:value="formData.evaluationName"
            :placeholder="$t('PleaseSelect') + $t('idp.evaluation_form')"
          />
          <span
            v-if="formData.status != 1"
            class="input-after btn"
            @click="
              $refs.evaluationRef.open({
                questionnaireId: formData.evaluationId,
                questionnaireName: formData.evaluationName,
              })
            "
          >
            {{ $t("idp.select_evaluation_form") }}
          </span>
        </a-form-item>
        <a-form-item
          v-else
          :label="$t('idp.evaluation_settings')"
          name="maxScore"
        >
          <div class="evaluation">
            <a-switch
              v-model:checked="formData.hasMaxScore"
              :disabled="formData.status == 1"
            />
            <span class="score">{{ $t("idp.full_score_of_100_points") }}</span>
            <span class="tips">{{ $t("idp.assessment_setting_tips") }}</span>
          </div>
        </a-form-item>
        <a-form-item
          :label="
            formData.completeCondition == 3
              ? $t('idp.evaluator')
              : $t('idp.reviewer')
          "
          name="auditors"
          :rules="{
            required: true,
            type: 'array',
            message:
              $t('PleaseSelect') +
              (formData.completeCondition == 3
                ? $t('idp.evaluator')
                : $t('idp.reviewer')),
            trigger: 'change',
          }"
        >
          <div>
            <a-tag
              v-if="formData.status != 1"
              style="border-style: dashed; cursor: pointer"
              @click="$refs.memberRef.open(formData.auditors)"
            >
              <plus-outlined />
              {{ $t("idp.select_personnel") }}
            </a-tag>
            <a-tag
              v-for="(item, index) in formData.auditors"
              :key="item.id"
              :closable="formData.status != 1"
              @close="formData.auditors.splice(index, 1)"
            >
              {{ item.name }}
            </a-tag>
          </div>
        </a-form-item>
      </a-form>
      <template v-if="formData.questionList && formData.questionList.length">
        <div class="part-title">
          <span class="h5">{{ $t("idp.evaluate_status") }}</span>
          <span
            >{{ $t("homework.AverageScore") }}：<i
              class="score"
              v-if="formData.status == 1"
              >{{ formData.score }}</i
            ><template v-else>--</template></span
          >
        </div>
        <div class="auditors-list">
          <div
            class="auditors-item"
            v-for="item in formData.questionList"
            :key="item.userID"
          >
            <span class="name">{{ item.realName }}</span>
            <span
              class="view"
              v-if="item.submittime"
              @click="viewEvaluation(item)"
              >{{ $t("homework.ViewEvaluation") }}</span
            >
            <span class="no-view" v-else>{{
              $t("homework.NotEvaluated")
            }}</span>
          </div>
        </div>
      </template>
      <template v-if="formData.flows && formData.flows.length">
        <h5 class="border-title">
          {{ $t("Pub_Lab_MarkingStatus") }}
          <!-- 批阅状态 -->
        </h5>
        <div class="check">
          <div
            class="check-item"
            :class="{ green: item.status == 1, red: item.status == 3 }"
            v-for="(item, index) in formData.flows"
            :key="index"
          >
            <p>
              <span>{{ $t("Pub_Lab_MarkingStatus") }}：</span>
              <!-- 批阅状态 -->
              <i class="status">{{ statusEnum[item.status] }}</i>
            </p>
            <template v-if="item.status != 2">
              <p>
                <span>{{ $t("homework.review_time") }}：</span>
                <!-- 批阅时间： -->
                {{ dateFormat(item.createTime) }}
              </p>
              <p>
                <span>{{ $t("homework.reviewer") }}：</span>
                <!-- 批阅人 -->
                {{ item.realName }}
              </p>
              <p v-if="formData.hasMaxScore">
                <span>
                  {{ $t("LB_Favorite_Score") }}：
                  <!-- 评分 -->
                </span>
                {{ item.score }}
              </p>
              <p>
                <span>
                  {{ $t("homework.comments") }}：
                  <!-- 评语 -->
                </span>
                {{ item.remark }}
              </p>
            </template>
          </div>
        </div>
      </template>
      <div class="btns">
        <a-button type="primary" @click="save" v-if="formData.status != 1">
          {{ $t("idp.save") }}
        </a-button>
        <a-button @click="back">
          {{ $t("idp.return") }}
        </a-button>
      </div>
    </div>

    <docPreview
      :isVisible="previewFile.visible"
      :documentId="previewFile.documentId"
      :fileStatus="previewFile.fileStatus"
      :fileType="previewFile.fileType"
      :filePath="previewFile.filePath"
      :fileName="previewFile.fileName"
      :fileHome="previewFile.fileHome"
      @previewEnd="
        (e) => {
          previewFile.visible = e;
        }
      "
    />
    <secretFile
      ref="secretFileRef"
      :config="{
        action: 'study/upload/file',
        accept: $t('upload.accept', [5, '200M']),
        maxSize: 200,
        limit: 5,
      }"
      @ok="upEnd"
    />
    <memberDrawer ref="memberRef" isMultiple @save="addAuditors" />
    <evaluationDrawer ref="evaluationRef" @save="addEvaluation" />
  </a-drawer>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
} from "vue";
import { useRouter } from "vue-router";

import { cloneDeep } from "lodash";
import { uploadUrl, getHeader, dateFormat, fileDownload } from "@/utils/tools";
import moment from "moment";

import memberDrawer from "./member.vue";
import evaluationDrawer from "./evaluation.vue";
import secretFile from "@/components/secretFile.vue";
import docPreview from "@/components/preview/index.vue";

export default defineComponent({
  components: {
    memberDrawer,
    evaluationDrawer,
    secretFile,
    docPreview,
  },
  setup(_, { emit }) {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      formData: {
        homeworkName: "",
        homeworkTime: [],
        steps: [],
        flows: [],
        completeCondition: 2,
        hasMaxScore: false,
        maxScore: 100,
        auditors: [],
        evaluationId: 0,
        evaluationName: "",
      },
      currentStep: 0,
      previewFile: {
        visible: false,
        documentId: 0,
        fileStatus: 0,
        fileType: 0,
        filePath: "",
        fileName: "",
        fileHome: "",
      },
    });
    const formRef = ref(null);

    const stepAdd = () => {
      if (state.formData.steps[state.currentStep].editing) {
        proxy.$message.warning(proxy.$t("homework.save_current_step"));
        // 请先保存当前编辑的步骤
      } else {
        state.formData.steps.push({
          content: "",
          files: [],
          lastTime: "",
          editing: true,
        });
        state.currentStep = state.formData.steps.length - 1;
      }
    };

    const stepDel = (index) => {
      if (
        !state.formData.steps[state.currentStep].stepId &&
        state.formData.steps.length > 1
      ) {
        proxy.$message.success(proxy.$t("CM_SuccessfullyDelete"));
        // 删除成功
        state.formData.steps.splice(index, 1);
        state.currentStep = 0;
      } else if (
        state.formData.steps[state.currentStep].editing &&
        state.formData.steps[state.currentStep].stepId
      ) {
        proxy.$message.warning(proxy.$t("homework.save_current_step"));
        // 请先保存当前编辑的步骤
      } else if (state.formData.steps.length <= 1) {
        proxy.$message.warning(proxy.$t("homework.keep_one_step"));
        // 至少保留一个步骤
      } else {
        // 删除成功
        state.formData.steps.splice(index, 1);
        state.currentStep = 0;
      }
    };

    const stepEdit = (index) => {
      if (state.formData.steps[state.currentStep].editing) {
        proxy.$message.warning(proxy.$t("homework.save_current_step"));
        // 请先保存当前编辑的步骤
      } else {
        state.formData.steps[index].editing = true;
        state.currentStep = index;
      }
    };

    const stepSave = (index) => {
      let item = state.formData.steps[index];
      if (item.files.length || item.content.trim() !== "") {
        item.attachments = item.files.map((file) => {
          if (file.status == "done") {
            return {
              fileName: file.response.data.name,
              filePath: file.response.data.file,
              fileType: file.response.data.fileType,
              name: file.response.data.name,
              file: file.response.data.exist
                ? file.response.data.md5
                : file.response.data.file,
              secretData: file.response.data.secretData,
              fileId: file.response.data.fileId,
            };
          }
        });
        if (item.attachments.length > 5) {
          proxy.$message.warning(
            proxy.$t("homework.upload_attachment_count", [5])
          );
          // 最多可上传5个附件
        } else {
          item.lastTime = Date.parse(new Date()) / 1000;
          item.editing = false;
        }
      } else {
        proxy.$message.warning(proxy.$t("homework.save_tip"));
        // 请填写内容或上传附件
      }
    };

    const secretFileRef = ref(null);
    let uploadIndex = 0;
    const handleUpload = (index) => {
      secretFileRef.value.show();
      uploadIndex = index;
    };

    const upEnd = ({ secret, file }) => {
      state.formData.steps[uploadIndex].files.push(...file);
    };

    const removeFile = (index, iFile) => {
      state.formData.steps[index].files.splice(iFile, 1);
    };

    let idpIndex = -1;
    const open = (data, index) => {
      idpIndex = -1;
      state.currentStep = 0;
      if (data) {
        idpIndex = index;
        state.formData = cloneDeep(data);
        state.formData.hasMaxScore = state.formData.maxScore == 100;
        state.formData.steps.forEach((item) => {
          item.editing = false;
          item.attachments = item.attachments || [];
          item.files = item.attachments.map((file) => {
            return {
              name:
                file.fileName +
                file.filePath.substr(file.filePath.lastIndexOf(".")),
              url: file.filePath,
              status: "done",
              secretData: file.secretData,
              response: {
                data: {
                  name: file.fileName,
                  file: file.file,
                  fileType: file.fileType,
                  fileId: file.fileId || 0,
                },
              },
            };
          });
        });
      } else {
        state.formData = {
          homeworkName: "",
          homeworkTime: [],
          steps: [
            {
              content: "",
              attachments: [],
              files: [],
              lastTime: "",
              editing: true,
            },
          ],
          flows: [],
          completeCondition: 2,
          hasMaxScore: false,
          maxScore: 100,
          auditors: [],
          evaluationId: 0,
          evaluationName: "",
        };
      }
      state.visible = true;
      formRef.value && formRef.value.resetFields();
    };

    const back = () => {
      state.visible = false;
    };

    const addAuditors = (data) => {
      state.formData.auditors = data;
      formRef.value.validateFields("auditors");
    };

    const addEvaluation = (data) => {
      state.formData.evaluationId = data.questionnaireId;
      state.formData.evaluationName = data.questionnaireName;
      formRef.value.validateFields("evaluationName");
    };

    const save = () => {
      formRef.value.validate().then(() => {
        let params = cloneDeep(state.formData);
        params.maxScore = params.hasMaxScore ? 100 : 0;
        if (params.steps[state.currentStep].editing) {
          proxy.$message.warning(proxy.$t("homework.save_current_step"));
          // 请先保存当前编辑的步骤
        } else {
          emit("save", { data: params, index: idpIndex });
          back();
        }
      });
    };

    const viewEvaluation = (item) => {
      router.push({
        path: "/questionnaire/homework",
        query: {
          questionnaireId: state.formData.evaluationId,
          planId: state.formData.planId,
          planDetailId: state.formData.detailId,
          planStatus: 1,
          userId: item.userID,
          disabled: 1,
        },
      });
    };

    return {
      uploadUrl,
      getHeader,
      dateFormat,
      fileDownload,
      moment,
      ...toRefs(state),
      formRef,
      stepAdd,
      stepDel,
      stepEdit,
      stepSave,
      secretFileRef,
      handleUpload,
      upEnd,
      removeFile,
      open,
      back,
      addAuditors,
      addEvaluation,
      save,
      viewEvaluation,
      statusEnum: {
        1: proxy.$t("CM_Pass"), // 通过
        2: proxy.$t("CM_Submit"), // 提交
        3: proxy.$t("XB_Unthread"), // 不通过
      },
    };
  },
});
</script>

<style lang="less" scoped>
.select-content {
  padding: 16px;

  .attachments {
    margin-top: 5px;

    &-item {
      padding: 0 10px;
      background-color: #f1f1f1;
      margin-bottom: 1px;
      .mixinFlex(space-between; center);

      p {
        margin: 0;
        width: 90%;
        .mixinEllipsis(30px);
      }

      &-p {
        cursor: pointer;

        &:hover {
          color: @color-theme;
        }
      }

      .down {
        &:hover {
          color: @color-theme;
        }
      }
    }
  }
  .steps {
    &-item {
      padding: 10px;
      border: 1px dashed #d9d9d9;
      margin-bottom: 10px;

      &-head {
        .mixinFlex(space-between; center);
        line-height: 30px;

        h6 {
          margin: 0;
        }

        .ctrl {
          .mixinFlex(flex-start; center);

          .span {
            margin-left: 16px;
          }

          &-icon {
            margin-left: 14px;
            cursor: pointer;
            font-size: 16px;

            &.edit {
              color: @color-theme;
            }

            &.del {
              color: red;
            }
          }
        }
      }

      &-content {
        .text {
          padding: 5px;
          margin: 8px 0 10px;
          background-color: #f1f1f1;
        }
      }

      &-files {
        .up-ctrl {
          .mixinFlex(flex-start; center);

          .explain {
            padding-left: 20px;
            margin: 0;
            color: #666;
          }
        }
      }
    }
  }
  .check {
    border-left: 1px solid @color-theme;
    margin-left: 20px;

    &-item {
      padding-left: 10px;
      position: relative;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .status {
        font-style: normal;
      }

      &::before {
        content: "";
        background-color: @color-theme;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        top: 9px;
        left: -3px;
      }

      &.green {
        &::before {
          background-color: green;
        }

        .status {
          color: green;
        }
      }

      &.red {
        &::before {
          background-color: red;
        }

        .status {
          color: red;
        }
      }

      p {
        margin-bottom: 2px;
        .mixinFlex(flex-start);

        span {
          width: 75px;
        }
      }
    }
  }
  .btns {
    text-align: center;
    margin-top: 24px;
    padding-top: 24px;
    .ant-btn {
      &:last-child {
        margin-left: 24px;
      }
    }
  }
  .evaluation {
    .mixinFlex(flex-start; center);
    .score {
      margin-left: 6px;
    }
    .tips {
      color: #999;
    }
  }
  .input-after {
    margin-left: 10px;
    color: #666;
    &.btn {
      color: @color-theme;
      cursor: pointer;
    }
  }
}
.tips-icon {
  color: #999;
  padding-left: 6px;
}
.upload-list {
  margin-top: 8px;
  padding: 2px 10px;
  background: #f5f5f5;
  .file-item {
    display: flex;
    align-items: center;
    margin: 6px 0;

    &-name {
      flex: 1;
      margin: 0 10px;
    }
  }
}
.part-title {
  margin: 16px 0;
  .mixinFlex(space-between; center);
  .h5 {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    padding-left: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      width: 4px;
      height: 24px;
      background-color: @color-theme;
      border-radius: 10px;
    }
  }
  .score {
    color: @color-theme;
    font-style: normal;
  }
}
.auditors-list {
  .auditors-item {
    .mixinFlex(space-between; center);
    padding: 6px 10px;
    margin: 8px 0;
    background-color: #f5f5f5;
    line-height: 22px;
    .view {
      color: @color-theme;
      cursor: pointer;
    }
    .no-view {
      color: #666;
    }
  }
}
</style>
